import React, { Component } from "react";
import { View, Image } from "react-native";
import "./openPresentation.css";
import { APP_COLOURS } from "../../APP_VARS";

const mainImages = {
	general: require("./ab1.png"),
	tetrice: require("./pres_bg@2x.webp"),
	techfocus: require("./TechFocus.png"),
	socialmedia: require("./ab1.png"),
	softwaredevelopment: require("./presentation1.webp"),
	reporting: require("./reporting@1x.webp"),
	community: require("./community@1x.png"),
	website: require("./software4@1x.png"),
	website2: require("./software.png"),
};

export default class DisclaimerPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					width: window.innerWidth,
					// borderBottomColor: APP_COLOURS.BG4,
					backgroundColor: "#000",
					borderBottomWidth: 1,
					minHeight: window.innerHeight,
					alignItems: "center",
				}}>
				{/* <View
					style={{
						width: window.innerWidth,
						borderBottomColor: APP_COLOURS.BG4,
						borderBottomWidth: 1,
						minHeight: window.innerHeight,
						position: "absolute",
					}}>
					<div class='disclaimerImage'></div>
				</View> */}

				<div className='disclaimer' style={this.props.fontSizes}>
					<View
						style={{
							padding: "8%",

							alignItems: "flex-start",
							justifyContent: "center",
							// maxWidth: 800,
							// marginHorizontal: "auto",

							width: "100%",
							minHeight: window.innerHeight,
							// position: "absolute",
							// top: 0,
							// left: 0,
							// paddingTop: 20,
							paddingBottom: 100,

							maxWidth: 1000,
							// backgroundColor: APP_COLOURS.BG5,
						}}>
						<Image
							source={require("./tetrice.png")}
							style={{
								width: 14 * (70 / 17),
								height: 14,
								resizeMode: "contain",
								// position: "absolute",
								// top: 15,
								// left: 0,
								// opacity: 0.8,
								marginBottom: 4,
							}}
						/>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								width: "100%",
								// padding: "2%",
							}}>
							<View
								style={{
									// maxWidth: 800,
									alignItems: "flex-start",
									width: "100%",
								}}>
								<h1> DISCLAIMER & CONFIDENTIALITY </h1>
								<p>
									The information presented in this report has
									been compiled at the client's request, using
									data provided by them and publicly available
									secondary industry reports or sources. While
									every effort has been made to ensure the
									accuracy and reliability of the information,
									no responsibility is assumed for its
									completeness, accuracy, or for any actions
									taken based on this information. The
									conclusions and recommendations drawn from
									this report are contingent upon the quality
									and integrity of the data provided and
									publicly recognized sources. We have not
									independently verified the information and
									do not guarantee its accuracy or
									completeness.
								</p>
								<p>
									Any third party reviewing this report is
									strongly encouraged to conduct their own
									independent investigations and inquiries to
									confirm the validity of the information
									contained herein. We cannot be held liable
									or responsible for the success of any
									strategies, recommendations, or
									implementations proposed in this report. We
									do not guarantee that your objectives will
									be met, and our fees are not contingent upon
									achieving a specific outcome. All advice and
									services are provided based on our best
									efforts and professional judgment.
								</p>
								<p>
									This document and the information it
									contains are highly confidential and
									proprietary. Unauthorized reproduction,
									distribution, or disclosure of this
									information is strictly prohibited without
									prior written consent. By accepting this
									document, the recipient agrees to maintain
									the confidentiality of the information
									contained herein and to use it solely for
									the purpose for which it was provided.
								</p>
							</View>
							{/* <View style={{ flex: 1 }} /> */}
						</View>

						<View
							style={{
								flex: 1,
								flexDirection: "row",
								justifyContent: "center",
								flexWrap: "wrap",
								width: "100%",

								justifyContent: "center",

								position: "absolute",
								bottom: 0,
								left: 0,
								paddingTop: 20,
							}}>
							<View
								style={{
									width: 150,
									marginBottom: 20,
									marginRight: "10%",
								}}>
								<img
									src={require("../../../assets/images/services.png")}
									alt='img'
									className='responsive'
								/>
							</View>
							<View
								style={{
									width: 150,
									marginBottom: 20,
								}}>
								<img
									src={require("../../../assets/images/infod.png")}
									alt='img'
									className='responsive'
								/>
							</View>
						</View>
					</View>
				</div>
			</View>
		);
	}
}
