import React, { Component } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
	Modal,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";
import SignaturePad from "./SignaturePade";
import AutoHeightImage from "../../AutoHeightImageComp";
import moment from "moment";
import Font from "react-font";

const PrintModal = ({ isVisible, onClose, data, signature }) => {
	console.log("PrintModal data:", data);

	// Create HTML content from the contract pages
	const contractContent = Object.values(data?.contractHtmlContent || {})
		.map((html) => html || "")
		.join("");

	// Add sendToApi function
	const sendToApi = async (htmlContent) => {
		try {
			const response = await fetch(
				"https://workflow.tetrice.co.za/webhook/5db91349-af19-414a-bfdf-3848b804dc64",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						html: htmlContent,
						contract_id: data?.contractData?._id,
						signature: signature,
						timestamp: moment().format("DD/MM/YYYY HH:mm"),
					}),
				},
			);

			if (!response.ok) {
				throw new Error("Failed to send HTML to API");
			}

			const result = await response.json();
			console.log("API Response:", result);

			// After successful API call, proceed with the original acceptProposal
			data.acceptProposal(signature, htmlContent);
			onClose();
		} catch (error) {
			console.error("Error sending HTML to API:", error);
		}
	};

	return (
		<Modal animationType='fade' transparent={true} visible={isVisible}>
			<View
				style={{
					width: window.innerWidth,
					height: window.innerHeight,
					backgroundColor: "rgba(0, 0, 0, 0.6)",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<View
					style={{
						width: "90%",
						maxWidth: 800,
						backgroundColor: "#FFF",
						borderRadius: 20,
						padding: 20,
						maxHeight: "90%",
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginBottom: 20,
						}}>
						<Text style={{ fontSize: 20, fontWeight: "bold" }}>
							Contract Summary
						</Text>
						<TouchableOpacity onPress={onClose}>
							<Text style={{ fontSize: 20 }}>×</Text>
						</TouchableOpacity>
					</View>

					{/* Print-ready content in iframe */}
					<iframe
						srcDoc={`
							<!DOCTYPE html>
							<html>
							<head>
								<style>
									@page {
										size: A4;
										margin: 0;
									}
									body {
										margin: 0;
										padding: 0;
										font-family: Arial, sans-serif;
										background-color: white;
									}
									.cover-page {
										width: 210mm;  /* A4 width */
										min-height: 297mm; /* A4 height as minimum */
										background-color: #171A20;
										page-break-after: always;
										position: relative;
										display: flex;
										flex-direction: column;
									}
									.cover-image {
										width: 210mm;  /* Exact A4 width */
										height: auto;  /* Maintain aspect ratio */
										display: block;
									}
									.cover-content {
										padding: 40px;
										color: white;
										margin-top: auto;
									}
									.cover-title {
										font-size: 32px;
										font-weight: bold;
										margin-bottom: 15px;
									}
									.cover-description {
										font-size: 16px;
										opacity: 0.8;
										max-width: 80%;
									}
									.contract-content {
										background-color: white;
										padding: 20mm;
										font-size: 11px;
										line-height: 1.3;
									}
									.signature-section {
										padding: 20px;
										text-align: center;
										background-color: white;
										border-top: 1px solid #ccc;
										margin-top: 40px;
									}
									.signature-timestamp {
										font-size: 12px;
										color: #666;
										font-style: italic;
										margin-top: 10px;
									}
									/* Rest of your content styling */
									.section { margin-bottom: 10px; }
									.section h2 { font-size: 14px; margin: 0 0 5px 0; }
									.cost-item { margin-bottom: 5px; }
									.cost-item strong { font-size: 12px; }
									.cost-item p { margin: 2px 0; }
								</style>
							</head>
							<body>
								<div class="cover-page">
									<img 
										src="https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/images%2FcoverTop.webp?alt=media&token=d84b7fea-18ca-445b-971a-1be895c09fc7"
										class="cover-image"
										alt="Cover"
									/>
									<div class="cover-content">
										<div class="cover-title">${data?.contractData?.title || "Contract"}</div>
										<div class="cover-description">${data?.contractData?.description || ""}</div>
									</div>
								</div>
								<div class="contract-content">
									${contractContent}
								</div>
								<div class="signature-section">
									<img src="${signature}" style="max-width: 150px;" />
									<div class="signature-timestamp">
										Signed and Accepted on ${moment().format("DD/MM/YYYY HH:mm")}
									</div>
								</div>
							</body>
							</html>
						`}
						style={{
							width: "100%",
							height: "70vh",
							border: "1px solid #ccc",
							borderRadius: 10,
						}}
					/>

					<TouchableOpacity
						onPress={() => {
							const htmlContent =
								document.querySelector("iframe").contentDocument
									.documentElement.outerHTML;
							sendToApi(htmlContent);
						}}
						style={{
							backgroundColor: APP_COLOURS.MAIN,
							padding: 15,
							borderRadius: 10,
							alignItems: "center",
							marginTop: 20,
						}}>
						<Text style={{ color: "#FFF", fontSize: 16 }}>
							Confirm and Send
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		</Modal>
	);
};

export default class CostEstimate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSignaturePad: false,
			base64Signature: "",
			includeCostEstimate: false,
			costEstimateModal: false,

			additionalItems: [],
			annuallyRecurring: [],
			monthlyRecurring: [],
			onceOffCosts: [],
			usageBased: [],
			showPrintModal: false,
		};
	}

	componentDidMount() {
		this.setState({
			...this.props,
		});

		console.log("console.log(this.props);", this.props);
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({
				...this.props,
			});
		}
	}

	render() {
		let w = 900;
		let { title, title2, end_date } = this.props;
		let {
			additionalItems,
			onceOffCosts,
			usageBased,
			monthlyRecurring,
			annuallyRecurring,
		} = this.state;

		if (!Array.isArray(additionalItems)) additionalItems = [];
		if (!Array.isArray(onceOffCosts)) onceOffCosts = [];
		if (!Array.isArray(usageBased)) usageBased = [];
		if (!Array.isArray(monthlyRecurring)) monthlyRecurring = [];
		if (!Array.isArray(annuallyRecurring)) annuallyRecurring = [];

		return (
			<View
				style={{
					// flex: 1,
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
					paddingBottom: "5%",
					// display: this.state.includeCostEstimate ? "flex" : "none",

					// backgroundColor: "#000",
					// backgroundColor: APP_COLOURS.BG3,
				}}>
				<View
					style={{
						// width: w,

						// height: h,
						height: "auto",
						maxWidth: 1000,
						padding: 20,
						marginTop: 50,
						width: "100%",
						flex: 1,
						zIndex: 1,
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<Text
							style={{
								fontSize: 45,
								color: "#FFF",
							}}>
							<Font family='Poppins' weight={700}>
								Cost estimate
							</Font>
						</Text>
					</View>
					<View
						style={{
							// flex: 1,
							flexDirection: "row",
							// height: h * 0.2,
							// width: w,
							// marginBottom: "40%",
						}}>
						<View
							style={{
								flex: 1,
								// padding: 20,
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<Text
									style={{
										fontSize: 35,
										color: APP_COLOURS.TEXTCOLOR,
									}}>
									<Font family='Poppins' weight={700}>
										{title}
									</Font>
								</Text>
								<Text
									style={{
										fontSize: 35 * 0.3,
										color: APP_COLOURS.TEXTCOLOR,
									}}>
									<Font family='Poppins' weight={500}>
										{title2}
									</Font>
								</Text>
							</View>
						</View>
					</View>

					<View
						style={{
							// flex: 1,
							flexDirection: "row",
							// height: h / 2,
							// width: w,

							marginBottom: 50,
						}}>
						<View
							style={{
								flex: 1,
							}}>
							<View
								style={{
									flexDirection: "row",
								}}></View>
							{/* <Text
								style={{
									fontSize: 15,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 25,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									This quotation is valid until until{" "}
									{moment(end_date).format("DD/MM/YYYY")}.
									Prices quoted are exclusive of taxes and may
									be subject to adjustment based on changes in
									project scope or unforeseen requirements.
									Payment terms are upfront unless otherwise
									stipulated. Any additional services
									requested outside the original scope will be
									quoted separately. Delivery timelines are
									estimates and may vary based on client
									feedback and approval processes. Project
									terms and conditions apply
								</Font>
							</Text> */}

							{/* <Text
								style={{
									fontSize: 19,
									color: "#FFF",
									marginBottom: 5,
									flexDirection: "row",
									marginTop: 5,
								}}>
								<Font family='Montserrat' weight={500}>
									Additional
								</Font>
							</Text> */}
							{additionalItems.map((d, i) => (
								<View
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										// flexDirection: "row",
										marginBottom: 10,
									}}>
									<Text
										style={{
											fontSize: 16,
											color: APP_COLOURS.TEXTCOLOR,
											paddingLeft: 12,
										}}>
										<Font family='Montserrat' weight={400}>
											{d.title}
										</Font>
									</Text>

									<Text
										style={{
											fontSize: 14,
											color: APP_COLOURS.TEXTCOLOR,
											paddingLeft: 12,
										}}>
										<Font family='Montserrat' weight={300}>
											{d.desc}
										</Font>
									</Text>
								</View>
							))}

							<View style={{ height: 40 }} />

							{onceOffCosts.length > 0 ? (
								<>
									<View
										style={{
											flexDirection: "row",
											borderTopWidth: 1,
											borderStyle: "dotted",
											borderColor: APP_COLOURS.TEXTCOLOR,
											paddingVertical: 10,
										}}>
										<View style={{ flex: 1 }}>
											<Text
												style={{
													fontSize: 20,
													color: "#FFF",
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={700}>
													Once off costs
												</Font>
											</Text>
										</View>
									</View>
									{onceOffCosts.map((d, i) =>
										this.costItem(d, `2${i}`),
									)}
									<View
										style={{
											width: "100%",
											alignItems: "flex-end",
										}}>
										<Text
											style={{
												fontSize: 18,
												color: APP_COLOURS.TEXTCOLOR,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={700}>
												R{" "}
												{onceOffCosts.reduce(
													(
														accumulator,
														currentValue,
													) => {
														return (
															accumulator +
															parseFloat(
																currentValue.totalExcl,
															)
														);
													},
													0,
												)}
											</Font>
										</Text>
									</View>
								</>
							) : null}

							{annuallyRecurring.length > 0 ? (
								<>
									<View
										style={{
											flexDirection: "row",
											borderTopWidth: 1,
											borderStyle: "dotted",
											borderColor:
												APP_COLOURS.DESELECTEDCOLOR,
											paddingVertical: 10,
											marginTop: 30,
										}}>
										<View style={{ flex: 1 }}>
											<Text
												style={{
													fontSize: 20,
													color: "#FFF",
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={700}>
													Annually recurring costs
												</Font>
											</Text>
										</View>
									</View>
									{annuallyRecurring.map((d, i) =>
										this.costItem(d, `2${i}`),
									)}
									<View
										style={{
											width: "100%",
											alignItems: "flex-end",
										}}>
										<Text
											style={{
												fontSize: 18,
												color: "#FFF",
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={700}>
												R{" "}
												{annuallyRecurring.reduce(
													(
														accumulator,
														currentValue,
													) => {
														return (
															accumulator +
															parseFloat(
																currentValue.totalExcl,
															)
														);
													},
													0,
												)}
											</Font>
										</Text>
									</View>
								</>
							) : null}

							{monthlyRecurring.length > 0 ? (
								<>
									<View
										style={{
											flexDirection: "row",
											borderTopWidth: 1,
											borderStyle: "dotted",
											borderColor:
												APP_COLOURS.DESELECTEDCOLOR,
											paddingVertical: 10,
											marginTop: 30,
										}}>
										<View style={{ flex: 1 }}>
											<Text
												style={{
													fontSize: 20,
													color: "#FFF",
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={700}>
													Monthly recurring costs
												</Font>
											</Text>
										</View>
									</View>
									{monthlyRecurring.map((d, i) =>
										this.costItem(d, `2${i}`),
									)}
									<View
										style={{
											width: "100%",
											alignItems: "flex-end",
										}}>
										<Text
											style={{
												fontSize: 18,
												color: "#FFF",
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={700}>
												R{" "}
												{monthlyRecurring.reduce(
													(
														accumulator,
														currentValue,
													) => {
														return (
															accumulator +
															parseFloat(
																currentValue.totalExcl,
															)
														);
													},
													0,
												)}
											</Font>
										</Text>
									</View>
								</>
							) : null}

							{usageBased.length > 0 ? (
								<>
									<View
										style={{
											flexDirection: "row",
											borderTopWidth: 1,
											borderStyle: "dotted",
											borderColor:
												APP_COLOURS.DESELECTEDCOLOR,
											paddingVertical: 10,
											marginTop: 30,
										}}>
										<View style={{ flex: 1 }}>
											<Text
												style={{
													fontSize: 20,
													color: "#FFF",
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={700}>
													Useage based costs
												</Font>
											</Text>
										</View>
									</View>
									{usageBased.map((d, i) =>
										this.costItem(d, `2${i}`),
									)}
									<View
										style={{
											width: "100%",
											alignItems: "flex-end",
										}}>
										<Text
											style={{
												fontSize: 19,
												color: "#FFF",
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={700}>
												R{" "}
												{usageBased.reduce(
													(
														accumulator,
														currentValue,
													) => {
														return (
															accumulator +
															parseFloat(
																currentValue.totalExcl,
															)
														);
													},
													0,
												)}
											</Font>
										</Text>
									</View>
								</>
							) : null}
						</View>
					</View>

					{/* <Text
						style={{
							fontSize: 13,
							color: APP_COLOURS.TEXTCOLOR,
							marginBottom: 2,
							flexDirection: "row",
							marginTop: 70,
						}}>
						<Font family='Montserrat' weight={300}>
							* All quoted amounts are monthly recurring and
							exclusive of VAT unless stated otherwise.
						</Font>
					</Text> */}

					<View
						style={{
							// width: w,
							width: "100%",
							// height: h,
							height: "auto",
							// maxWidth: 1200,
							borderRadius: 15,

							flex: 1,
							zIndex: 1,
							backgroundColor: "#FFF",
							flexDirection: "row",
							overflow: "hidden",
							flexWrap: "wrap",
							// height: 300,
						}}>
						<View style={{ flex: 1, padding: 20, minWidth: 250 }}>
							{this.state.base64Signature !== "" ? (
								<View
									style={{
										padding: 20,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: "#FFF",
										borderRadius: 10,
										width: "100%",
									}}>
									<AutoHeightImage
										width={200}
										source={{
											uri: this.state.base64Signature,
										}}
									/>
									<View
										style={{
											borderTopColor: "#000",
											// width: "90%",
											borderTopWidth: 1,
											borderStyle: "dotted",
											// height: 1,
											width: "100%",
										}}>
										<Text
											style={{
												width: "100%",
												textAlign: "center",
												color: "#000",
												fontSize: 16,
												fontWeight: "600",
												padding: 5,
											}}>
											Accept
										</Text>
									</View>
									<Text
										style={{
											width: "100%",
											textAlign: "center",
											color: "#000",
											fontSize: 12,
											fontWeight: "300",
											padding: 5,
											fontStyle: "italic",
										}}>
										signed at{" "}
										{moment().format("DD/MM/YYYY HH:mm")}
									</Text>
								</View>
							) : (
								<View
									style={{
										flex: 1,
										padding: 20,
										justifyContent: "flex-end",
										alignItems: "flex-end",
										// height: 350,
										borderRadius: 10,
										marginBottom: 40,
										width: "100%",
									}}>
									<View
										style={{
											borderTopColor: "#000",
											// width: "90%",
											borderTopWidth: 1,
											borderStyle: "dotted",
											height: 1,
											width: "100%",
										}}>
										<Text
											style={{
												width: "100%",
												textAlign: "center",
												color: "#000",
												fontSize: 16,
												fontWeight: "600",
												padding: 5,
											}}>
											Accept this cost estimate by signing
											below
										</Text>
									</View>
								</View>
							)}
							<TouchableOpacity
								onPress={async () => {
									if (this.state.base64Signature !== "") {
										this.setState({ showPrintModal: true });
									} else {
										this.setState({
											showSignaturePad: true,
										});
									}
								}}
								style={{
									width: "100%",
									backgroundColor: APP_COLOURS.MAIN,
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 20,
									padding: 10,
									marginBottom: 20,
									marginHorizontal: "auto",
								}}>
								{this.state.loading ? (
									<ActivityIndicator color='#FFF' />
								) : (
									<Text
										style={{
											width: "100%",
											textAlign: "center",
											color: "white",
											fontSize: 16,
											fontWeight: "600",
											padding: 5,
										}}>
										{this.state.base64Signature !== ""
											? "Accept"
											: "Add signature"}
									</Text>
								)}
							</TouchableOpacity>
						</View>
						<View
							style={{
								alignItems: "flex-end",
								justifyContent: "flex-end",
							}}>
							<Image
								source={require("./agreeMent.png")}
								style={{
									width: 200 * (368.94 / 255.42),
									height: 200,
									resizeMode: "contain",
								}}
							/>
						</View>
					</View>
				</View>

				<PrintModal
					isVisible={this.state.showPrintModal}
					onClose={() => this.setState({ showPrintModal: false })}
					data={{
						contractData: {
							...this.props,
							contractRows: this.props.contractRows || [],
						},
						contractHtmlContent: this.props.contractHtmlContent,
						onceOffCosts: this.state.onceOffCosts,
						monthlyRecurring: this.state.monthlyRecurring,
						annuallyRecurring: this.state.annuallyRecurring,
						usageBased: this.state.usageBased,
						additionalItems: this.state.additionalItems,
						acceptProposal: this.props.acceptProposal,
					}}
					signature={this.state.base64Signature}
				/>

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.showSignaturePad}>
					<View
						style={{
							width: window.innerWidth,
							height: window.innerHeight,
							backgroundColor: "rgba(0, 0, 0, 0.6)",
							justifyContent: "center",
							alignItems: "center",
						}}>
						<TouchableOpacity
							activeOpacity={1}
							style={{
								width: window.innerWidth,
								height: window.innerHeight,
								backgroundColor: "rgba(0, 0, 0, 0.6)",
								zIndex: 10,
								position: "absolute",
								top: 0,
							}}
							onPress={() => {
								this.setState({
									accept: true,
									showSignaturePad: false,
								});
							}}
						/>
						<View
							style={{
								width: "100%",
								maxWidth: 700,
								bottom: 0,
								zIndex: 999,
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 4,
								},
								shadowOpacity: 0.23,
								shadowRadius: 4.65,
								elevation: 8,
								backgroundColor: "#FFF",
								borderRadius: 20,
								padding: 10,
							}}>
							<SignaturePad
								onEnd={(base64Signature) => {
									// console.log(base64Signature);
									clearTimeout(this._timeout);
									this._timeout = setTimeout(() => {
										this.setState({
											base64Signature,
										});
									}, 1000);
								}}
							/>
							<View
								style={{
									// position: "absolute",
									bottom: 150,
									borderTopColor: "#000",
									// width: "90%",
									borderTopWidth: 1,
									borderStyle: "dotted",
									height: 1,
									width: "100%",
								}}>
								<Text
									style={{
										width: "100%",
										textAlign: "center",
										color: "#000",
										fontSize: 16,
										fontWeight: "600",
										padding: 5,
									}}>
									Signature
								</Text>
							</View>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										accept: true,
										showSignaturePad: false,
									});
								}}
								style={{
									flex: 1,
									backgroundColor: APP_COLOURS.MAIN,
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 20,
									padding: 10,
									width: "100%",
									marginHorizontal: "auto",
								}}>
								<Text
									style={{
										width: "100%",
										textAlign: "center",
										color: "white",
										fontSize: 16,
										fontWeight: "600",
										padding: 5,
									}}>
									Done
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</Modal>
			</View>
		);
	}

	costItem = (d, i) => {
		return (
			<View
				key={i}
				onMouseEnter={() => this.setState({ hover: i })}
				onMouseLeave={() => this.setState({ hover: "" })}
				style={{
					marginBottom: 10,
					// marginT: 6,
					alignItems: "flex-start",
					justifyContent: "space-between",
					flexDirection: "row",
					width: "100%",
					// backgroundColor:
					// 	this.state.hover === i
					// 		? APP_COLOURS.WHITE_TRANSPARENT3
					// 		: APP_COLOURS.WHITE_TRANSPARENT4,
					// padding: 5,

					borderRadius: 5,
				}}>
				<View
					style={{
						flex: 1,
						borderLeftWidth: 0.5,
						borderLeftColor: APP_COLOURS.BACK_TRANSPARENT2,
						paddingLeft: 16,
					}}>
					<Text
						style={{
							fontSize: 15,
							color: APP_COLOURS.TEXTCOLOR,
							flexDirection: "row",
						}}>
						<Font family='Montserrat' weight={500}>
							{d.title}
						</Font>
					</Text>
					<Text
						style={{
							fontSize: 12,
							color: APP_COLOURS.TEXTCOLOR,
							flexDirection: "row",
							opacity: 0.7,
						}}>
						<Font family='Montserrat' weight={300}>
							{d.desc}
						</Font>
					</Text>
				</View>
				<Text
					style={{
						fontSize: 17,
						color: APP_COLOURS.TEXTCOLOR,
						flexDirection: "row",
					}}>
					<Font family='Montserrat' weight={300}>
						R {d.totalExcl}
					</Font>
				</Text>
			</View>
		);
	};
}
