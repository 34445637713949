import React, { useEffect, Component } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useParams,
	useLocation,
} from "react-router-dom";
import OpenPresentation from "./OpenPresentation";
import "./openPresentation.css";

export const PresentationUrl = () => {
	const { presentation_id, token } = useParams();

	useEffect(() => {}, [presentation_id, token]);

	return <OpenPresentation presentation_id={presentation_id} token={token} />;
};
