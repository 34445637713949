import React, { Component } from "react";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Lottie from "react-lottie";
import * as animationData from "./sending.json";

export default class SendingFeedback extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					backgroundColor: "#000",
					width: window.innerWidth,
					overflow: "hidden",
					height: window.innerHeight,
					alignItems: "center",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						padding: 5,
						paddingBottom: 100,
					}}>
					<Text
						style={{
							color: "#FFF",
							fontSize: 19,
							fontWeight: "800",
							fontFamily: "Gotham",
							opacity: 0.8,
						}}>
						Sending...
					</Text>
					<Fade left>
						<View style={{}}>
							<Lottie
								options={{
									loop: true,
									autoplay: true,
									animationData: animationData,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice",
									},
								}}
								height={90}
								width={90}
							/>
						</View>
					</Fade>
				</View>
			</View>
		);
	}
}
