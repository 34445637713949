import React, { Component } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
	TextInput,
	Modal,
} from "react-native";
import ContractCoverPage from "./ContractCoverPage";
import logo_tetrice from "../../../assets/images/logo_tetrice.png";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";
// import OpenPresentationData from "./OpenPresentationData";

import starFill from "./starFill.png";
import star from "./star.png";
import AutoHeightImage from "../../AutoHeightImageComp";
import CostEstimate from "./CostEstimate";
import SendingFeedback from "./SendingFeedback";
import FeedBackSent from "./FeedBackSent";
import DisclaimerPage from "./DisclaimerPage";
import LazyPresentation from "./OpenPresentationLaz";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

export default class OpenContract extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			showSidebar: false,
			loading: true,

			pageNumber: 0,

			contract_id: this.props.contract_id,
			contractData: "",
			contractPages: [],
			contractPageData: "",
			lockContent: true,

			sharedList: [],

			contractRows: [],

			feedback: "",
			starRating: 0,

			sendingFeedback: false,
			feedBackSent: false,
			contractHtmlContent: [],
		};
	}

	async componentDidMount() {
		let accessToken = this.props.token;
		let contract_id = this.props.contract_id;
		console.log("contract_id", contract_id);
		console.log("Approved User", accessToken);
		// return;
		if (accessToken !== null) {
		} else {
			await localStorage.clear();
			// await this.setState({
			// 	loading: false,
			// });
		}

		await this.fetchContractData(accessToken, contract_id);

		await window.addEventListener("resize", this.resize.bind(this));
		await this.resize();

		this.setState({
			// loading: false,
			contractData: this.props.contractData,
			contractRows:
				typeof this.props.contractData?.contractRows !== "undefined"
					? this.props.contractData?.contractRows
					: [],
		});

		setTimeout(() => {
			this.setState({
				loading: false,
			});
		}, 2000);
	}

	fetchContractData = async (token, contract_id) => {
		this.setState({
			loading: true,
			contractData: "",
			contractPages: [],
			contractPageData: "",
		});
		// let url =
		// 	"https://workflow.tetrice.co.za/webhook/b4a6a94d-ded9-4cb2-af6e-565b8c2a2be3";

		let url =
			"https://workflow.tetrice.co.za/webhook/49b1de58-1d3f-4850-b16d-bf4024c89011";
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				_id: contract_id,
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					// loading: false,
					contractData: data,
					contractRows:
						typeof data?.contractRows !== "undefined"
							? data?.contractRows
							: [],
				});
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	getLargeHtmlData = (geturl, next) => {
		// POST this data from the parent list or create a new contract
		let url =
			"https://workflow.tetrice.co.za/webhook/59af9ff2-5530-45b9-b4d8-fb9631c555fb";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				url: geturl,
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((html) => {
				return next(html);
			})
			.catch((error) => {
				this.setState({
					error: error,
					// loading: false,
				});
			});
	};

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		const { screenWidth } = this.state;
		const isSmallScreen = screenWidth < 1100;
		// const scaleFactor = isSmallScreen ? 0.8 : 1.0;
		// const pageStyle = {
		// 	transform: `scale(${scaleFactor})`,
		// };

		let fs = window.innerWidth * 0.07;
		// let maxFont = 40;
		// let h1 = fs < maxFont ? fs : maxFont;
		// let h2 = h1 / 1.618;
		// let pp = h2 / 1.618;

		let pp = isSmallScreen ? 10 : 17;
		let h3 = pp * 1.1618;
		let h2 = pp * 1.298;
		let h1 = h2 * 1.1618;
		let h0 = h1 * 1.9618;
		let maxFont = 40;

		let mw = 1000;

		let bg = APP_COLOURS.BG;

		if (this.state.sendingFeedback) {
			return <SendingFeedback />;
		} else if (this.state.feedBackSent) {
			return <FeedBackSent />;
		} else
			return (
				<View
					onLayout={() => {
						this.setState({
							// loading: false,
						});
					}}
					style={{
						width: "100%",
						height: this.state.screenHeight,
						// overflow: "hidden",
						backgroundColor: bg,
						flexDirection: "row",
					}}>
					<View
						style={{
							flex: 1,
						}}>
						<ScrollView
							ref={(ref) => {
								this.scrollRef = ref;
							}}
							overScrollMode='never'
							showsVerticalScrollIndicator={false}
							// pagingEnabled={true}
							// decelerationRate='fast'
							// scrollEventThrottle={160}
							// snapToInterval={this.state.screenHeight}
							contentContainerStyle={
								{
									// alignItems: "center",
								}
							}>
							<DisclaimerPage
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								mw={mw}
								fontSizes={{
									"--h1": `${h0}px`,
									"--h2": `${h1}px`,
									"--h4": `${h3}px`,
									"--pp": `${pp}px`,
								}}
							/>
							<ContractCoverPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								mw={mw}
							/>

							<View
								style={{
									marginHorizontal: "auto",
								}}>
								{this.state.contractRows
									.sort((a, b) => {
										if (
											parseInt(a.order) <
											parseInt(b.order)
										)
											return -1;
										if (
											parseInt(a.order) >
											parseInt(b.order)
										)
											return 1;
										return 0;
									})
									.map((d, i) => (
										<View
											style={{
												borderBottomColor:
													APP_COLOURS.BG3,
												borderBottomWidth: 0.5,
												// maxWidth: 1400,
												// flex: 1,
												width: "100%",

												// alignItems: "center",
												justifyContent: "center",

												// minHeight:
												// 	window.innerHeight * 0.99,
											}}>
											<View
												style={{
													position: "absolute",
													right: 10,
													top: 10,
												}}>
												<Text
													style={{
														fontWeight: "400",
														fontFamily:
															"Avenir Next",
														fontSize: 12,
														color: "#0098FD",
														marginBottom: 4,
														zIndex: 99,
														padding: 4,
														borderRadius: 20,
														backgroundColor: bg,
													}}>
													{parseInt(i) + 1}
												</Text>
											</View>
											<View
												style={{
													width: "100%",
													flex: 1,
													flexDirection: isSmallScreen
														? "column"
														: "row",
													// maxHeight: window.innerHeight,

													paddingBottom: 5,
													marginVertical: 10,

													// padding: "2%",
													// alignItems: "center",
													// justifyContent: "center",
													paddingTop: "4%",
													// maxWidth: 1200,
												}}>
												{d.rowData.map((rd, rdIn) => (
													<View
														key={rd._id}
														onMouseEnter={() =>
															this.setState({
																hover: d.id,
															})
														}
														onMouseLeave={() =>
															this.setState({
																hover: "",
															})
														}
														style={{
															// minWidth: 290,
															// flex: isSmallScreen
															// 	? "auto"
															// 	: 1,
															// flex: 1,
															paddingRight: "4%",
															paddingLeft: "2%",

															marginRight: 10,
															width: isSmallScreen
																? window.innerWidth
																: (window.innerWidth *
																		0.8) /
																  d.rowData
																		.length,
															maxWidth: 1000,
														}}>
														{/* <Text
															style={{
																color: "#FFF",
																fontSize: 20,
															}}>
															{}
														</Text> */}
														<View
															style={{
																flexDirection:
																	"row",
																marginBottom: 5,
															}}>
															{rdIn === 0 ? (
																<Image
																	source={require("./tetrice.png")}
																	style={{
																		width:
																			pp *
																			(70 /
																				17),
																		height: pp,
																		resizeMode:
																			"contain",
																		position:
																			"absolute",
																		top: -pp,
																		left: 0,
																		// opacity: 0.8,
																	}}
																/>
															) : null}

															{/* <View
															style={{
																width: 200,
																// flex: 1,
																marginBottom: 20,
																marginRight:
																	"10%",
															}}>
															<img
																src={require("../../../assets/images/tetrice_logo.png")}
																alt='img'
																className='responsive'
															/>
														</View> */}
														</View>
														<LazyPresentation
															contract_id={
																this.state
																	.contract_id
															}
															pageData={rd}
															reloadData={(
																html,
															) => {
																this.addHtmlContent(
																	html,
																	rd._id,
																);
															}}
															lockContent={
																this.state
																	.lockContent !==
																rd._id
															}
															h0={h0}
															h1={h1}
															h2={h2}
															pp={pp}
															mw={mw}
															fontSizes={{
																"--h1": `${h0}px`,
																"--h2": `${h1}px`,
																"--h4": `${h3}px`,
																"--pp": `${pp}px`,
															}}
															index={rd._id}
														/>
														{/* <OpenPresentationData
															
														/> */}
													</View>
												))}
											</View>
										</View>
									))}

								<CostEstimate
									{...this.state.contractData}
									title={this.state.contractDatatitle}
									title2={this.state.contractDatatitle2}
									end_date={this.state.contractDataend_date}
									contractHtmlContent={
										this.state.contractHtmlContent
									}
									acceptProposal={(
										base64Signature,
										htmlContent,
									) => {
										this.updateFeedback({
											contract_id: this.props.contract_id,
											feedback: "Signed & Accepted",
											accepted: true,
											base64Signature: base64Signature,
											htmlContent: htmlContent,
											starRating: this.state.starRating,
										});
									}}
								/>

								<View
									style={{
										flex: 1,
										flexDirection: "row",
										// justifyContent: "space-between",
										flexWrap: "wrap",
										width: "100%",
										padding: "1%",
										justifyContent: "center",
										marginTop: 50,
									}}>
									<View
										style={{
											width: 200,
											// flex: 1,
											marginBottom: 20,
											marginRight: "10%",
										}}>
										<img
											src={require("../../../assets/images/services.png")}
											alt='img'
											className='responsive'
										/>
									</View>
									<View
										style={{
											width: 200,
											marginBottom: 20,
										}}>
										<img
											src={require("../../../assets/images/infod.png")}
											alt='img'
											className='responsive'
										/>
									</View>
								</View>

								<View
									style={{
										width: "100%",
										marginTop: 100,
										justifyContent: "center",
									}}>
									<View
										style={{
											// width: 250,
											width: "100%",
											flexDirection: "row",
											marginBottom: 20,
											justifyContent: "center",
										}}>
										{[1, 2, 3, 4, 5, 6, 7].map(
											(priority) => (
												<TouchableOpacity
													key={priority}
													style={{
														marginRight: 5,
													}}
													onPress={async () => {
														this.setState({
															starRating:
																priority,
														});
													}}>
													{this.state.starRating >=
													priority ? (
														<AutoHeightImage
															width={35}
															style={{
																marginRight: 5,
															}}
															source={starFill}
														/>
													) : (
														<AutoHeightImage
															width={35}
															style={{
																marginRight: 5,
																opacity: 0.3,
															}}
															source={star}
														/>
													)}
												</TouchableOpacity>
											),
										)}
									</View>

									<View
										style={{
											padding: 10,
											maxWidth: 500,
											width: "100%",
											marginHorizontal: "auto",
										}}>
										<Text
											style={{
												fontWeight: "400",
												fontFamily: "Avenir Next",
												fontSize: 12,
												color: "#0098FD",
												marginBottom: 4,
												position: "absolute",
												top: -4,

												zIndex: 99,
												padding: 4,
												left: 20,
												borderRadius: 20,
												backgroundColor: bg,
											}}>
											Feedback
										</Text>

										<TextInput
											ref={(ref) =>
												(this.feedbackinputRef = ref)
											}
											style={{
												fontSize: 16,
												color: "#FFF",
												fontWeight: "400",
												fontFamily: "Avenir Next",
												width: "100%",
												paddingLeft: 10,
												padding: 20,
												borderWidth: 1,
												borderColor: "#0098FD",
												borderRadius: 20,
												borderWidth: 1,
												minHeight: 30,
												backgroundColor: bg,
											}}
											placeholderTextColor={"#797979"}
											autoCapitalize='sentences'
											multiline
											numberOfLines={5}
											clearButtonMode='while-editing'
											// autoCompleteType="off"
											// value={feedback}
											placeholder={`Type your feedback`}
											onChangeText={(text) => {
												this.setState({
													feedback: text,
												});
											}}
										/>
										<TouchableOpacity
											style={{
												width: "100%",
												borderRadius: 10,
												justifyContent: "center",
												alignItems: "center",
												marginVertical: 25,
												flexDirection: "row",
												overflow: "hidden",
												marginTop: 30,
												opacity:
													this.state.feedback !== ""
														? 1
														: 0.2,
											}}
											onPress={() => {
												// setTimeout(() => {
												this.updateFeedback({
													contract_id:
														this.props.contract_id,

													feedback:
														this.state.feedback,
													starRating:
														this.state.starRating,
												});
												// }, 1500);
											}}>
											<Image
												source={
													require("./button.svg")
														.default
												}
												style={{
													width: "101%",
													height: 45,
													resizeMode: "cover",
												}}
											/>

											<Text
												style={{
													fontSize: 16,
													// marginTop: 12,
													fontWeight: "600",
													color: "#FFF",
													position: "absolute",
												}}>
												Send
											</Text>
										</TouchableOpacity>
									</View>
								</View>

								<View
									style={{
										height: 300,
									}}
								/>
							</View>
						</ScrollView>
					</View>

					{this.state.loading ? (
						<Modal
							animationType='none'
							transparent={true}
							visible={this.state.loading}>
							<View
								style={{
									width: window.innerWidth,
									height: window.innerHeight,
									backgroundColor: "rgba(0, 0, 0, 0.6)",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<TouchableOpacity
									activeOpacity={1}
									style={{
										width: window.innerWidth,
										height: window.innerHeight,
										backgroundColor: "rgba(0, 0, 0, 0.6)",
										zIndex: 10,
										position: "absolute",
										top: 0,
									}}
									onPress={() => {
										this.setState({
											accept: true,
											showSignaturePad: false,
										});
									}}
								/>
								<View
									style={{
										// width: window.innerWidth,
										height: window.innerHeight,
										backgroundColor: bg,
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										position: "absolute",
										top: 0,
										zIndex: 999,
										width: "100%",
									}}>
									<Image
										source={logo_tetrice}
										style={{
											width: 150,
											height: 100,
											resizeMode: "contain",
										}}
									/>
								</View>
							</View>
						</Modal>
					) : null}
				</View>
			);
	}

	updateFeedback = (obj) => {
		this.setState({
			sendingFeedback: true,
		});
		let url =
			"https://workflow.tetrice.co.za/webhook/0e3474fd-75c3-4c62-870d-b34961372276";
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${this.props.token}`,
			},
			body: JSON.stringify(obj),
		};
		fetch(url, requestOptions)
			.then((response) => {
				this.setState({
					sendingFeedback: false,
					feedBackSent: true,
				});
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	updateDocument = (obj) => {
		// let url =
		// 	"https://workflow.tetrice.co.za/webhook/fa683270-ffec-4542-beff-a2acafd63bfd";
		// const requestOptions = {
		// 	method: "PUT",
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 		authorization: `Bearer ${this.props.contract_id}`,
		// 	},
		// 	body: JSON.stringify(obj),
		// };
		// fetch(url, requestOptions)
		// 	.then((response) => {
		// 		if (!response.ok) {
		// 			throw new Error("Failed to fetch data");
		// 		}
		// 		return response.json();
		// 	})
		// 	.then((data) => {
		// 		console.log(data);
		// 		this.setState({
		// 			contractData: data,
		// 			contractRows: data.contractRows,
		// 			loading: false,
		// 		});
		// 	})
		// 	.catch((error) => {
		// 		this.setState({
		// 			error: error,
		// 			loading: false,
		// 		});
		// 	});
	};

	addHtmlContent = (html, index) => {
		this.setState((prevState) => ({
			contractHtmlContent: {
				...prevState.contractHtmlContent,
				[index]: html,
			},
		}));
	};
}
