// import React, { Component } from "react";
import React, { useState, useEffect, Component } from "react";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useParams,
	useLocation,
} from "react-router-dom";
import { Dimensions } from "react-native";
import Login from "./Unauthed/Login";
import { PresentationUrl } from "./OpenUrls/Presentations";
import { ContractUrl } from "./OpenUrls/Presentations/ContractUrl";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

function useQuery() {
	// WORKS FOR QURY URL
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			console.log("Polly fill for smooth scroll in safari");
		} else {
			// production code
		}

		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	render() {
		return (
			<Router>
				<Routes>
					<Route path='/login' element={<Login />} />

					<Route
						path='/presentation/:presentation_id/:token'
						element={<PresentationUrl />}
					/>

					<Route
						path='/contract/:contract_id/:token'
						element={<ContractUrl />}
					/>

					<Route path='*' element={<Navigate to='/login' />} />
				</Routes>
			</Router>
		);
	}
}

export default index;
