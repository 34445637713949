import React, { Suspense, useState, useEffect, useRef } from "react";
import { View, ActivityIndicator, Text } from "react-native";
import useInView from "./useInView"; // Import the custom hook
import "./openPresentation.css"; // Import any global CSS here
import OpenPresentationData from "./OpenPresentationData";

// Uncomment if you want to lazy load OpenPresentationData
// const OpenPresentationData = lazy(() => import("./OpenPresentationData"));

const LazyLoadComponent = ({
	fontSizes,
	pageData,
	reloadData,
	lockContent,
	presentation_id,
	h0,
	h1,
	h2,
	h3,
	pp,
	mw,
	index,
}) => {
	// Initialize refs for each component dynamically
	const refs = useRef([]);
	if (!refs.current[index]) {
		refs.current[index] = React.createRef();
	}

	const [loadedComponents, setLoadedComponents] = useState({});

	const [isInView, setIsInView] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsInView(entry.isIntersecting);
			},
			{ threshold: 0.5 },
		);

		if (refs.current[index].current) {
			observer.observe(refs.current[index].current);
		}

		return () => {
			if (refs.current[index].current) {
				observer.unobserve(refs.current[index].current);
			}
		};
	}, [index]);

	useEffect(() => {
		if (isInView && !loadedComponents[index]) {
			setLoadedComponents((prevState) => ({
				...prevState,
				[index]: true,
			}));
		}
	}, [isInView, loadedComponents, index]);

	const isComponentLoaded = (index) => loadedComponents[index];

	return (
		<div
			style={{
				height: isComponentLoaded(index)
					? "auto"
					: window.innerHeight / 2,
			}}
			ref={refs.current[index]} // Assign dynamic ref here
		>
			{isComponentLoaded(index) ? (
				<OpenPresentationData
					presentation_id={presentation_id}
					fontSizes={fontSizes}
					pageData={pageData}
					reloadData={reloadData}
					lockContent={lockContent}
					h0={h0}
					h1={h1}
					h2={h2}
					h3={h3}
					pp={pp}
					mw={mw}
				/>
			) : (
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",

						height: window.innerHeight / 2,
					}}>
					<View style={{ width: 100, marginBottom: 10 }}>
						<Text style={{ color: "#FFF" }}>Loading...</Text>
					</View>
					<ActivityIndicator color={"#FFF"} />
				</View>
			)}
		</div>
	);
};

export default LazyLoadComponent;
